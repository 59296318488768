@import '../assets/styles/styles.scss';

// Projects Page

#projects{
    .project-list{
      counter-reset: item;
      list-style-type: none;
      li{
        @include position($p: relative);
        &:first-child{
          margin-top: 0;
        }
        &:before{
          @include position($p: absolute);
          display: block;
          content: counter(item, decimal-leading-zero) '';
          counter-increment: item;
          @include font($f: $hkBold, $s: 15px);
          color: $primary;
        }
        a{
          display: inline-block;
          @include font($f: $hkSemiBold);
          @include fluid-typo(22px, 38px);
          @include fluid-line(30px, 54px);
          color: $heading;
          text-transform: capitalize;
        }
      }
    }
    // .project-image{
    //   background-image: url(images/img-jeewit-bachan.jpg);
    // }
    @media #{$tablet-lg}{
      .project-container{
        display: flex;
        padding-bottom: 60px;
      }
      .project-titles{
        background-color: #F7F7F7;
        padding: 5% 8%;
        display: flex;
        align-items: center;
      }
      .project-list{
        li{
          margin-top: 20px;
          &:before{
            @include position($l: -40px, $t: 50%);
            @include translator($y: -50%);
            color: $heading;
            opacity: 0.2;
          }
          a{
            opacity: 0.2;
          }
          &:hover, &.active{
            &:before{
              color: $primary;
              opacity: 1;
            }
            a{
              opacity: 1;
            }
          }
        }
      }
    }
    @media #{$phablet}{
      .project-titles{
        padding: 50px 0;
      }
      .project-list{
        li{
          margin-top: 40px;
          &:before{
            @include position($l: 0px, $t: 7.5px);
          }
          a{
            @include font($f: $hkBold);
            margin-left: 30px;
          }
        }
        .pl-image{
          margin-top: 10px;
          height: 50vh;
        }
      }
      .project-image{
        display: none;
      }
    }
  }