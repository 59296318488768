// Footer
footer#footer{
  padding: 40px 0;
}

// Footer Inner
.footer-inner{
  display: flex;
  align-items: center;
}

// Footer Brand
.footer-logo{
  width: 140px;
  a{
    display: inline-block;
  }
  img{
    width: 100%;
  }
}

// Footer Info
.footer-info{
  width: calc(100% - 140px);
  text-align: center;
  @include font($f: $hkMedium, $s: 12px);
  color: $black;
  span{
    margin: 0 5px;
  }
}

@media #{$tablet}{
  .footer-info{
    text-align: right;
  }
}
@media #{$screen-xs}{
  .footer-inner{
    display: block;
  }
  .footer-logo,.footer-info{
    width: 100%;
  }
  .footer-logo{
    text-align: center;
    img{
      max-width: 140px;
    }
    margin-bottom: 5px;
  }
  .footer-info{
    text-align: center;
  }
}

