@import '../assets/styles/styles.scss';

// Projects Single Page
.project-details{
  display: flex;
  flex-direction: column;
}

.single-project{
    h4{
      margin-bottom: 30px;
      @include font($f: $hkSemiBold);
      @include fluid-line(32px, 42px)
    }
    .amount-loader{
      margin-bottom: 30px;
    }
    .loader-container{
      @include position($p: relative);
      max-width: 360px;
      width: 100%;
      height: 20px;
      margin-bottom: 10px;
      background-color: #E6E6E6;
      z-index: 2;
      .loader{
        @include position($p: absolute, $t: 0, $l: 0);
        z-index: 3;
        height: 100%;
        background-color: $primary;
      }
    }
    .loader-text{
      @include font($f: $hkSemiBold, $s: 14px);
    }
    .loader-text-inner{
      @include position($p: absolute, $t: 50%, $l: 10px);
      @include translator($y: -50%);
      @include font($f: $hkSemiBold, $s: 12px);
      color: $white;
      z-index: 4;
    }
    p{
      // text-transform: capitalize;
      margin-top: 30px;
      &:first-child{
        margin-top: 0;
      }
    }
    .btn-container{
      margin-top: 40px;
      
      button {
        background-color: Transparent;
        background-repeat:no-repeat;
        border: none;
        cursor:pointer;
        overflow: hidden;
        outline:none;
      }
    }
  
    // Video Library Section
    &.video-project{
      padding: 0;
    }
    .video-wrapper{
      background-color: $bills;
      @include position($p: relative, $t: 0, $r: 0, $b: 0, $l: 0);
      overflow: hidden;
      height: 100%;
      z-index: 2;
      video{
        display: block;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
      .icon-play{
        @include position($p: absolute, $t: 50%, $l: 50%);
        @include translator(-50%, -50%);
        @include radius(50%);
        width: 60px;
        height: 60px;
        border: 2px solid #AFAFAF;
        background-size: 19px 24px;
        cursor: pointer;
        z-index: 5;
      }
    }
    .iframe-wrapper{
      @include position($p: relative);
      padding-bottom: 56.25%;
      height: 0;
      iframe{
        @include position($p: absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
      }
    }
    @media #{$tablet-lg}{
      .sp-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .video-wrapper{
        video{
          @include position($p: absolute, $t: 0, $l: 0);
        }
      }
    }
    @media #{$phablet}{
      .sp-image{
        height: 50vh;
      }
      .video-wrapper{
        video{
          @include position($p: relative);
        }
      }
    }
    @media #{$mobile}{
      .loader-container{
        max-width: 100%;
      }
    }
  }