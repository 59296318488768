@import '../assets/styles/styles.scss';


// Header
#header{
    @include position($p: fixed, $t: 0, $l: 0);
    background-color: $white;
    width: 100%;
    z-index: 9999;
  }
  
  // Wrapper
  .wrapper{
    @include position($p: relative);
  }
  
  // Site logo
  .site-logo{
    a{
      display: block;
    }
    img{
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
  
  // Site Navigation
  .site-navigation{
    justify-content: flex-end;
  }
  .nav-container{
    align-items: center;
  }
  .nav-item{
    @include font($f: $hkSemiBold);
    text-transform: uppercase;
    align-items: center;
    a{
      color: $heading;
      display: block;
    }
    &:first-child{
      margin-left: 0;
    }

    &:hover,&.active{
        a{
          color: $primary;
        }
      }

    &.nav-give{
      a{
        color: $white;
        background-color: $primary;
        padding: 6px 30px;
      }
    }
    
  }
  
  // Hamburger Nav
.site-mobile-nav{
  @include position($p: fixed, $r: 20px, $t: 37px);
  width: 32px;
  height: 32px;
  z-index: 99999;
  div{
    @include position($p: absolute, $l: 0);
    @include transition();
    width: 100%;
    height: 2px;
    background-color: $hamburger;
    &:nth-child(1){
      @include position($t: 11px);
    }
    &:nth-child(2){
      @include position($t: 19px);
    }
  }
  &.open{
    div{
      @include position($t: 15px, $l: 0);
      &:nth-child(1){
        transform: rotate(45deg);
      }
      &:nth-child(2){
        transform: rotate(-45deg);
      }
    }
  }
}
  // Mobile Responsive
  @media #{$desktop}{
    .header-inner{
      padding: 25px 0;
    }
    .site-logo{
      width: 230px;
    }
    .site-navigation{
      width: calc(100% - 230px);
    }
    .nav-item{
      margin-left: 50px;
      @include font($s: 15px);
      line-height: 24px;
    }
  }
  @media #{$tablet-lg}{
    .header-inner{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .site-mobile-nav{
      display: none;
    }
  }
  @media #{$tab-portrait}{
    .header-inner{
      padding: 20px 0 40px;
    }
    .site-logo{
      width: 130px;
    }
    .site-navigation{
      width: calc(100% - 130px);
    }
    .nav-item{
      margin-left: 30px;
      @include font($s: 12px);
      line-height: 20px;
      &.nav-give{
        a{
          padding: 5px 25px;
        }
      }
    }
  }
  @media #{$phablet}{
    
    #header{
      background-color: #F7F7F7;
    }
    .header-inner{
      padding: 15px 0;
    }
    .site-logo{
      width: 180px;
    }
    .site-navigation{
      @include position($p: fixed, $l: 100%, $t: 86px);
      @include transition();
      width: 100%;
      height: calc(100vh - 86px);
      background-color: $white;
      &.open{
          left: 0;
      }
    }
    .nav-container{
      @include position($p: absolute, $l: 0, $t: 50%);
      @include translator($y: -50%);
      display: block !important;
      width: 100%;
      padding: 0 20px;
      text-align: center;
    }
    .nav-item{
      @include font($f: $evBold, $s: 27px);
      line-height: 36px;
      text-transform: capitalize;
      margin-top: 15px;
      a{
        color: $hamburger;
        display: inline-block;
      }
      &:first-child{
        margin-top: 0;
      }
      &.nav-give{
        @include font($f: $hkBold, $s: 16px);
        line-height: 25px;
        margin-top: 80px;
        text-transform: uppercase;
        a{
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
  @media #{$phab-portrait}{
    .nav-item{
      &.nav-give{
        a{
          padding-left: 100px;
          padding-right: 100px;
        }
      }
    }
  }
  @media #{$mobile}{
    .nav-item{
      &.nav-give{
        a{
          display: block;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
  