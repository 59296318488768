// HTML
html{
  width: 100%;
  height: 100%;
}

// Body
body {
  width: 100%;
  overflow-x: hidden;
  @include font($f: $hkRegular, $s: $font-size-base);
}

// Wrapper
.wrapper{
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
.wrapper-md{
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
.wrapper-sm{
  max-width: 1045px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

// Headings
h1{
  @include font($f: $evBold);
  @include fluid-typo(28px, 55px);
}
h2{
  @include font($f: $hkSemiBold);
  @include fluid-typo(24px, 47px);
}
h3{
  @include font($f: $hkRegular);
  @include fluid-typo(22px, 38px);
}
h4{
  @include font($f: $hkBold);
  @include fluid-typo(20px, 28px);
}
h5{
  @include font($f: $hkBold);
  @include fluid-typo(18px, 24px);
}
h6{
  @include font($f: $hkSemiBold);
  @include fluid-typo(16px, 22px);
}

// Paragraphs
p{
  @include font($f: $hkRegular);
  &.p-xl{
    @include font($s: 24px);
    line-height: 32px;
  }
  &.p-lg{
    @include font($s: 20px);
    line-height: 28px;
  }
  &.p-md{
    @include font($s: 18px);
    line-height: 26px;
  }
  &.p-sm{
    @include font($s: 16px);
    line-height: 24px;
  }
}

// Anchor Tag
a{
  text-decoration: none;
}

// Flex
.d-flex{
  display: flex;
}
.d-inline-flex{
  display: inline-flex;
}

// Background Image
.bg-image{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// Container - With margin-top
.container-mt{
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    height: calc(100vh - 100px);
    margin-top: 100px;
  }
  @media only screen and (min-width: 1024px){
    display: flex;
    height: calc(100vh - 100px);
    margin-top: 100px;
  }
  @media #{$desktop}{
    height: calc(100vh - 121px);
    margin-top: 121px;
  }
  @media #{$phablet}{
    margin-top: 86px;
  }
}

// Section - HTML Sections
section{
  @media #{$tablet-lg}{
    padding: 50px 0;
  }
  @media #{$desktop}{
    padding: 70px 0;
  }
  @media #{$desktop-sm}{
    padding: 90px 0;
  }
  @media #{$phablet}{
    padding: 50px 0;
  }
}

// No Padding
.no-padding{
  padding: 0;
}

// Back to Homepage arrow
.backTo{
  @include position($p: absolute, $l: 20px);
  a{
    display: inline-flex;
    align-items: center;
    @include font($f: $hkMedium, $s: 14px);
    color: $pagination;
  }
  img{
    max-width: 17px;
    width: 100%;
    margin-right: 5px;
  }
  @media #{$tablet-lg}{
    @include position($t: 105px);
  }
  @media #{$desktop}{
    @include position($t: 130px);
  }
  @media #{$desktop-lg}{
    @include position($t: 150px);
  }
  @media #{$desktop-xl}{
    @include position($t: 180px);
  }
  @media #{$phablet}{
    display: none;
  }
}

// Classes in body
body{
  &.projects{
    .backTo{
      @include position($l: 6.5%);
    }
  }
  &.autoHeight{
    section{
      height: auto;
    }
  }
}
