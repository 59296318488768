// Media Queries - From mobile to desktop
$screen-xs: "screen and (max-width: 575px)";
$screen-sm: "screen and (min-width: 576px)";
$screen-md: "screen and (min-width: 768px)";
$screen-lg: "screen and (min-width: 992px)";
$screen-xl: "screen and (min-width: 1200px)";

// Media Queries - From desktop to mobile
$mobile: "screen and (max-width: 480px)";
$phab-landscape: "screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape)";
$phab-portrait: "screen and (min-width: 481px) and (max-width: 767px)";
$phablet: "screen and (max-width: 767px)";
$tablet-lg: "screen and (min-width: 768px)";
$tab-landscape: "screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)";
$tab-portrait: "screen and (min-width: 768px) and (max-width: 1024px)";
$tablet: "screen and (max-width: 1024px)";
$laptop: "screen and (min-width: 1025px) and (max-width: 1280px)";
$desktop: "screen and (min-width: 1025px)";
$desktop-sm: "screen and (min-width: 1281px)";
$desktop-md: "screen and (min-width: 1366px)";
$desktop-lg: "screen and (min-width: 1440px)";
$desktop-xl: "screen and (min-width: 1681px)";

// Primary Colors
$white: #FFFFFF;
$black: #000000;
$primary: #6BC72C;
$heading: #2D3136;
$label: #2D2D2D;
$bills: #E0E0E0;
$pagination: #848484;
$placeholder: #212121;
$questionMark: #DFDFDF;
$borderLight: #E4E4E4;
$borderDark: #BDBDBD;
$counterSub: #959595;
$hamburger: #333333;

// Typography
$hkLight: 'HKGrotesk-Light';
$hkRegular: 'HKGrotesk-Regular';
$hkMedium: 'HKGrotesk-Medium';
$hkSemiBold: 'HKGrotesk-SemiBold';
$hkBold: 'HKGrotesk-Bold';
$evRegular: 'Evolventa-Regular';
$evBold: 'Evolventa-Bold';

// Base Font Size
$font-size-base: 18px;