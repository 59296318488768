@import '../assets/styles/styles.scss';

// About Page
.about-us{
    h2,h4,p{
      text-transform: capitalize;
    }
    h2,h4,.p-lg{
      margin-bottom: 40px;
    }
    h4{
      @include font($f: $hkMedium);
      @include fluid-line(32px, 42px)
    }
    .p-lg{
      @include fluid-typo(18px, 20px);
      @include fluid-line(26px, 28px);
    }
    .p-sm{
      padding-left: 70px;
      @include position($p: relative);
      &:before{
        @include position($p: absolute, $t: 8px, $l: 0px);
        display: block;
        content: '';
        width: 39px;
        height: 30px;
        background-image: url(../assets/images/quote-green.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    .author{
      @include font($s: 14px);
      text-align: right;
      color: $primary;
      margin-top: 10px;
    }
    &.rn-about-bottom{
      background-color: $bills;
      .p-sm{
        padding-left: 0;
        margin-top: 30px;
        &:first-child{
          margin-top: 0;
        }
        &:before{
          display: none;
        }
      }
    }
    @media #{$tablet-lg}{
      &.rn-about-top{
        padding-top: 60px;
      }
      .rn-about-container{
        display: flex;
      }
    }
    @media #{$phablet}{
      h2{
        br{
          display: none;
        }
      }
      h2,h4,.p-lg{
        margin-bottom: 30px;
      }
      .p-sm{
        padding-left: 50px;
        &:before{
          width: 25px;
          height: 19px;
        }
      }
      .rn-about-image{
        height: 50vh;
      }
    }
  }