@import './components/assets/styles/styles.scss';

// Home Page

// vh section
.vh-section{
    width: 100%;
    height: auto;
  }
  
  // Scroll Slides | Home Page Main Slides
  .scroll-slides{
    @include position($p: relative);
    display: inline-block;
  }
  
  // Hero Image Section
  .hero-section{
    @include position($p: relative);
    height: 100%;
    width: 100%;
    background-position: center top;
    z-index: 4;
    overflow: hidden;
  }
  .hero-text{
    z-index: 6;
    &.hero-rear{
      z-index: 3;
      max-width: 675px;
    }
    &.hero-face{
      max-width: 675px;
    }
  }
  .hero-title{
    @include fluid-line(44px, 71px);
    color: $heading;
    margin-left: -138px;
    &.title-face{
      color: $white;
    }
  }
  .hero-content{
    margin-top: 65px;
    padding-left: 115px;
    width: 515px;
    p{
      @include font($f: $hkSemiBold, $s: 16px);
      @include position($p: relative);
      text-transform: capitalize;
      line-height: 24px;
      color: $white;
      &:after{
        @include position($p: absolute, $t: 10px, $l: -35px);
        width: 24px;
        height: 18px;
        display: block;
        content: '';
        background-image: url(./components/assets/images/quote.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    .author{
      @include font($f: $hkSemiBold, $s: 16px);
      display: inline-block;
      margin-top: 15px;
      color: $white;
    }
  }
  .hero-counter{
    @include position($p: absolute, $b: 60px);
    @include font($f: $hkMedium);
    color: $black;
    z-index: 6;
    letter-spacing: -1px;
    span{
      color: $counterSub;
      letter-spacing: 1px;
    }
  }

  // Slider Progress Bar
.slide-progress{
    @include position($p: fixed, $b: 0, $l: 0);
    width: 100%;
    height: 5px;
    background-color: $white;
    z-index: 9999;
    .progress-bar{
    @include position($p: absolute, $t: 0, $l: 0);
    height: 100%;
    background-color: $primary;
    }
  }
  
  // Everlasting - Banner 1
  .everlasting{
    .hero-section{
      &:after{
        @include position($p: absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
        display: block;
        content: '';
        z-index: 5;
        opacity: 0.74;
  
        background: rgb(43,41,41);
        background: -moz-linear-gradient(19.2deg, rgba(43,41,41,1) 22.31%, rgba(43,41,41,0) 82.39%);
        background: -webkit-linear-gradient(19.2deg, rgba(43,41,41,1) 22.31%, rgba(43,41,41,0) 82.39%);
        background: linear-gradient(19.2deg, rgba(43,41,41,1) 22.31%, rgba(43,41,41,0) 82.39%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2b2929",endColorstr="#2b2929",GradientType=1);
      }
    }
  }
  
  // About Us - Banner 2
  .about-us{
    .au-container{
      @include position($p: relative);
      align-items: flex-end;
    }
    .au-image{
      @include position($p: relative);
      height: 100%;
      &:after{
        @include position($p: absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
        display: block;
        content: '';
        opacity: 0.74;
  
        background: rgb(43,41,41);
        background: -moz-linear-gradient(56.85deg, rgba(43,41,41,1) 1.07%, rgba(43,41,41,0) 58.76%);
        background: -webkit-linear-gradient(56.85deg, rgba(43,41,41,1.07) 1%, rgba(43,41,41,0) 58.76%);
        background: linear-gradient(56.85deg, rgba(43,41,41,1) 1.07%, rgba(43,41,41,0) 58.76%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2b2929",endColorstr="#2b2929",GradientType=1);
      }
    }
    .au-contents{
      h3{
        @include fluid-line(30px, 53px);
        color: #3C3C3C;
      }
    }
    .newsletter{
      @include position($p: relative);
      &:before{
        @include position($p: absolute, $t: 0px, $l: 0);
        height: 1px;
        display: block;
        content: '';
        background-color: #B8B8B8;
      }
      h4{
        @include fluid-line(28px, 36px);
        color: $primary;
      }
      form{
        margin-top: 25px;
      }
    }
  }
  
  // Features Project - Banner 3
  .features-projects{
    .fp-item{
      @include position($p: relative);
      &:before{
        @include position($p: absolute, $t: 0, $l: 0);
        width: 100%;
        height: 100%;
        display: block;
        content: '';
  
        background: rgb(21,21,21);
        background: -moz-linear-gradient(360deg, rgba(21,21,21,1) -8.01%, rgba(116,116,116,0) 66.23%);
        background: -webkit-linear-gradient(360deg, rgba(21,21,21,1) -8.01%, rgba(116,116,116,0) 66.23%);
        background: linear-gradient(360deg, rgba(21,21,21,1) -8.01%, rgba(116,116,116,0) 66.23%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#151515",endColorstr="#747474",GradientType=1);
      }
      .fp-content{
        @include position($p: absolute);
        h4{
          @include font($f: $hkSemiBold);
          color: $white;
        }
      }
  
      .btn-more{
        @include font($s: 15px);
        color: $white;
      }
    }
  }
  
  // Get in Touch - Banner 4
  .get-in-touch{
    .hero-title{
      &.title-face{
        color: $heading;
      }
    }
    .git-contact,.git-form{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .git-contact{
      background-color: #F2F2F2;
      p{
        &.p-xl{
          @include fluid-typo(20px, 24px);
          @include fluid-line(28px, 32px);
        }
      }
    }
    .git-box{
      margin-top: 30px;
      &:first-child{
        margin-top: 0;
      }
      .git-title{
        @include font($f: $hkBold, $s: 16px);
        margin-bottom: 7px;
        display: inline-block;
        color: $black;
      }
    }
    .git-form{
      h4{
        margin-bottom: 40px;
      }
    }
    .form-element{
      margin-top: 15px;
      &:first-child{
        margin-top: 0;
      }
      &.form-element-submit{
        margin-top: 25px;
      }
    }
  }
  
  
  @media #{$tablet-lg}{
    .vh-section{
      height: 100vh;
    }
  
    // Container Right | Scroll Container
    .scroll-container{
      @include position($p: absolute, $t: 0, $l: 0);
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100vh;
      height: 100vw;
      z-index: 5;
  
      -webkit-transform: rotate(-90deg) translateX(-100%);
      -moz-transform: rotate(-90deg) translateX(-100%);
      -ms-transform: rotate(-90deg) translateX(-100%);
      -o-transform: rotate(-90deg) translateX(-100%);
      transform: rotate(-90deg) translateX(-100%);
  
      -webkit-transform-origin: top left;
      -moz-transform-origin: top left;
      -ms-transform-origin: top left;
      -o-transform-origin: top left;
      transform-origin: top left;
    }
  
    // Scroll Wrapper
    .scroll-wrapper{
      -webkit-transform: rotate(90deg) translateY(-100vh);
      -moz-transform: rotate(90deg) translateY(-100vh);
      -ms-transform: rotate(90deg) translateY(-100vh);
      -o-transform: rotate(90deg) translateY(-100vh);
      transform: rotate(90deg) translateY(-100vh);
  
      -webkit-transform-origin: top left;
      -moz-transform-origin: top left;
      -ms-transform-origin: top left;
      -o-transform-origin: top left;
      transform-origin: top left;
  
      white-space: nowrap;
      font-size: 0;
    }
    .scroll-slides{
      height: 100vh;
      margin-left: 115px;
      width: calc(100vw - 115px);
      white-space: normal;
    }
    .hero-text{
      @include position($p: absolute, $t: 36.5%, $l: 0);
    }
    .hero-title{
      margin-left: -80px;
    }
    .hero-content{
      margin-top: 40px;
    }
    .hero-counter{
      @include position($l: -80px);
      @include font($s: 34px);
      span{
        @include font($s: 16px);
      }
    }
    .about-us{
      .au-container{
        width: calc(100% - 30px);
        height: calc(100% - 60px);
      }
      .au-image{
        width: 200px;
        margin-right: 30px;
      }
      .au-contents{
        width: calc(100% - 230px);
        h3{
          br{
            display: none;
          }
        }
      }
      .newsletter{
        margin-top: 50px;
        padding-top: 30px;
        &:before{
          width: 70%;
        }
        form{
          display: block;
          .form-element{
            width: 100%;
            margin: 20px 0 0;
            &:first-child{
              margin-top: 0;
            }
          }
          .form-element-submit{
            label{
              display: none;
            }
          }
        }
      }
    }
    .features-projects{
      .fp-item{
        .btn-container{
          @include transition();
          margin-top: 0;
          opacity: 0;
          visibility: hidden;
        }
        &:hover{
          .btn-container{
            margin-top: 20px;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .get-in-touch{
      .container-mt{
        display: flex;
        flex: 1;
        height: 100%;
      }
      .git-contact{
        padding: 15% 5% 0;
      }
      .git-form{
        padding: 0 5%;
      }
      .form-element-submit{
        input{
          width: 150px;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .features-projects{
      .fp-item{
        @include position($p: relative);
        width: 100%;
        height: calc(100%/3);
        .fp-content{
          @include position($p: absolute, $l: 20px, $b: 40px);
        }
      }
    }
  }
  @media only screen and (min-width: 1024px){
    .features-projects{
      .fp-item{
        @include position($p: relative);
        width: calc(100%/3);
        height: 100%;
        .fp-content{
          @include position($l: 30px, $b: 40px);
        }
      }
    }
  }
  @media #{$desktop}{
    .about-us{
      .au-container{
        height: calc(100% - 60px);
      }
      .au-image{
        width: 300px;
        margin-right: 50px;
      }
      .au-contents{
        width: calc(100% - 350px);
      }
      .newsletter{
        margin-top: 100px;
        padding-top: 40px;
        &:before{
          width: 80%;
        }
        form{
          display: inline-flex;
          .form-element-email{
            margin: 0 15px;
          }
          .form-element-submit{
            label{
              color: transparent;
            }
            width: 150px;
          }
          .form-element{
            margin-top: 0;
          }
          .form-element-name,.form-element-email{
            width: calc(100%/2 - 180px/2);
          }
          .form-element-submit{
            label{
              display: inline-block;
            }
          }
        }
      }
    }
    .features-projects{
      .fp-item{
        .fp-content{
          @include position($l: 50px, $b: 60px);
        }
      }
    }
  }
  @media #{$desktop-sm}{
    .about-us{
      .newsletter{
        &:before{
          width: 85%;
        }
      }
    }
  }
  @media #{$desktop-md}{
    .scroll-slides{
      margin-left: 190px;
      width: calc(100vw - 190px);
    }
    .hero-title{
      margin-left: -120px;
    }
    .hero-counter{
      @include position($l: -115px);
      @include font($s: 40px);
      span{
        @include font($s: 18px);
      }
    }
    .about-us{
      .au-container{
        height: calc(100% - 90px);
        width: calc(100% - 90px);
      }
      .au-image{
        width: 400px;
        margin-right: 60px;
      }
      .au-contents{
        width: calc(100% - 460px);
      }
    }
  }
  @media #{$desktop-lg}{
    .about-us{
      .newsletter{
        &:before{
          width: 90%;
        }
      }
    }
  }
  @media #{$desktop-xl}{
    .scroll-slides{
      margin-left: 250px;
      width: calc(100vw - 250px);
    }
    .hero-title{
      margin-left: -138px;
    }
    .hero-counter{
      @include position($l: -140px);
      @include font($s: 50px);
      span{
        @include font($s: 20px);
      }
    }
    .about-us{
      .au-container{
        height: calc(100% - 100px);
        width: calc(100% - 150px);
      }
      .au-image{
        width: 500px;
        margin-right: 60px;
      }
      .au-contents{
        width: calc(100% - 560px);
        h3{
          br{
            display: initial;
          }
        }
      }
      .newsletter{
        &:before{
          width: 95%;
        }
      }
    }
    .get-in-touch{
      .git-form{
        padding: 0 12% 0 5%;
      }
    }
  }
  
  
  @media #{$phablet}{
    .vh-section{
      padding: 0;
      height: 100vh;
    }
    .scroll-slides{
      width: 100%;
      margin-left: 0;
      padding: 50px 20px 100px;
      display: block;
      .container-mt{
        margin-top: 0;
        height: 100%;
      }
    }
    .hero-text{
      &.hero-rear{
        display: none;
      }
      margin-bottom: 20px;
    }
    .hero-title{
      margin-left: 0;
    }
    .hero-content{
      margin-top: 100px;
      padding-left: 0;
      p{
        @include font($f: $hkMedium);
        &:after{
          top: -30px;
          left: 0;
          display: none;
        }
      }
      .author{
        @include font($f: $hkMedium);
      }
    }
    .hero-counter{
      @include position($l: 20px, $b: 20px);
      @include font($f: $hkSemiBold, $s: 40px);
      span{
        @include font($f: $hkSemiBold, $s: 20px);
        color: $heading;
      }
    }
    .everlasting{
      height: 100vh;
      padding: 0;
      .hero-section{
        &:after{
          background: #0B0B0B;
          opacity: 0.8;
        }
      }
      .hero-text{
        @include position($p: absolute, $l: 20px, $t: 150px);
        width: calc(100% - 40px);
        margin-bottom: 0;
      }
      .arrow-nav{
        display: block;
      }
      .hero-counter{
        color: $white;
        span{
          color: $counterSub;
        }
      }
    }
    .about-us{
      .hero-title{
        &.title-face{
          color: $heading;
        }
      }
      .au-container{
        height: auto;
        width: 100%;
        align-items: initial;
      }
      .au-image{
        display: none;
      }
      .au-contents{
        width: 100%;
        h4{
          br{
            display: none;
          }
        }
      }
      .newsletter{
        margin-top: 60px;
        padding-top: 25px;
        &:before{
          width: 100%;
        }
        form{
          display: block;
        }
        .form-element{
          width: 100% !important;
          margin: 20px 0 0;
        }
        .form-element-submit{
          label{
            display: none;
          }
        }
      }
    }
    .features-projects{
      background-color: #F2F2F2;
      .hero-title{
        &.title-face{
          color: $heading;
          br{
            display: none;
          }
        }
      }
      .fp-item{
        height: 60vh;
        margin-top: 20px;
        &:first-child{
          margin-top: 0;
        }
        .fp-content{
          @include position($l: 30px, $b: 60px);
          h4{
            br{
              display: none;
            }
          }
        }
      }
    }
    .get-in-touch{
      .git-contact{
        padding: 40px 20px;
        margin-bottom: 40px;
      }
      .git-form{
        h4{
          margin-bottom: 25px;
        }
      }
    }
  }
  @media #{$phab-portrait}{
    .hero-content{
      width: 75%;
    }
  }
  @media #{$screen-xs}{
    .hero-section{
      background-position: right 35% top;
      padding-top: 60px;
    }
    .hero-content{
      width: 100%;
    }
  }
  @media #{$mobile}{
    .hero-title {
      font-size: 25px;
      line-height: 35px;
    }
    .hero-content{
      margin-top: 45px;
    }
    .about-us{
      .au-contents{
        h3{
          br{
            display: none;
          }
        }
      }
    }
  }

.container-mt {
  flex: 1;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}


/* for mac scroll issue */

@media (min-width: 768px){
  .mac-scroll{
      overflow: hidden;
  }
}