
@import './components/assets/styles/styles.scss';

/* Evolventa Regular */
@font-face {
    font-family: 'Evolventa-Regular';
    src: url('fonts/Evolventa-Regular.eot');
    src: url('fonts/Evolventa-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Evolventa-Regular.svg#Evolventa-Regular') format('svg'),
    url('fonts/Evolventa-Regular.ttf') format('truetype'),
    url('fonts/Evolventa-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* Evolventa Bold */
  @font-face {
    font-family: 'Evolventa-Bold';
    src: url('fonts/Evolventa-Bold.eot');
    src: url('fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg'),
    url('fonts/Evolventa-Bold.ttf') format('truetype'),
    url('fonts/Evolventa-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* HK Grotesk Regular */
  @font-face {
    font-family: 'HKGrotesk-Light';
    src: url('fonts/HKGrotesk-Light.eot');
    src: url('fonts/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/HKGrotesk-Light.svg#HKGrotesk-Light') format('svg'),
    url('fonts/HKGrotesk-Light.ttf') format('truetype'),
    url('fonts/HKGrotesk-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* HK Grotesk Regular */
  @font-face {
    font-family: 'HKGrotesk-Regular';
    src: url('fonts/HKGrotesk-Regular.eot');
    src: url('fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg'),
    url('fonts/HKGrotesk-Regular.ttf') format('truetype'),
    url('fonts/HKGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* HK Grotesk Medium */
  @font-face {
    font-family: 'HKGrotesk-Medium';
    src: url('fonts/HKGrotesk-Medium.eot');
    src: url('fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/HKGrotesk-Medium.svg#HKGrotesk-Medium') format('svg'),
    url('fonts/HKGrotesk-Medium.ttf') format('truetype'),
    url('fonts/HKGrotesk-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* HK Grotesk Semi Bold */
  @font-face {
    font-family: 'HKGrotesk-SemiBold';
    src: url('fonts/HKGrotesk-SemiBold.eot');
    src: url('fonts/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('fonts/HKGrotesk-SemiBold.svg#HKGrotesk-SemiBold') format('svg'),
    url('fonts/HKGrotesk-SemiBold.ttf') format('truetype'),
    url('fonts/HKGrotesk-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* HK Grotesk Bold */
  @font-face {
    font-family: 'HKGrotesk-Bold';
    src: url('fonts/HKGrotesk-Bold.eot');
    src: url('fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg'),
    url('fonts/HKGrotesk-Bold.ttf') format('truetype'),
    url('fonts/HKGrotesk-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  