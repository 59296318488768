@import '../assets/styles/styles.scss';

    .section-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      margin: 40px 0;

      .section {
        max-width: 520px;
        width: 100%;
        height: 324px;
        box-shadow: 1px 1px 8px rgba($color: #000, $alpha: 0.1);
        position: relative;
        margin: 0 15px 20px 0;

        &:hover {
          box-shadow: 0px 14px 24px rgba($color: #000, $alpha: 0.36);
          transform: scale(1.01);
        }

        .background-image {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
        }

        .amount-loader {
          margin-bottom: 30px;
          width: 100%;
          @include position($p: absolute);
          top: 0;
          height: 100%;
          padding: 5em 8% 8% 5%;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.05));

        }

        .loader-container{
          @include position($p: relative);
          max-width: 360px;
          width: 100%;
          height: 25px;
          margin-bottom: 10px;
          background-color: #E6E6E6;
          z-index: 2;
          top: 10px;
          .loader{
            @include position($p: absolute, $t: 0, $l: 0);
            z-index: 3;
            height: 100%;
            background-color: $primary;
          }
        }

        .loader-text-inner{
          @include position($p: absolute, $t: 50%, $l: 10px);
          @include translator($y: -50%);
          @include font($f: $hkSemiBold, $s: 14px);
          color: $white;
          z-index: 4;
        }

        .loader-text{
          @include font($f: $hkSemiBold, $s: 14px);
        }

        .loader-text-link { 
          @include font($f: $hkSemiBold, $s: 12px);
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        
          a {
            text-decoration: none;
            color: #fff;
            font-size: 15px;
            &:hover {
              color: $primary;
              font-weight: bold;
              border-bottom: 2px solid $primary;
              span {
                color: $primary;
              }
            }
          }
          span {
            margin-left: 10px;
            font-size: 20px;
            margin-top: 3px;
          }
          button {
            background-color: Transparent;
            background-repeat:no-repeat;
            border: none;
            cursor:pointer;
            overflow: hidden;
            outline:none;
        }
      }

        
        p{
          text-transform: capitalize;
          margin-top: 30px;
          &:first-child{
            margin-top: 0;
          }
        }
    
        @media #{$tablet-lg}{
          @media #{$mobile}{
            .section {
              max-width: 100%;
            }
          }
        }
      }
    }

