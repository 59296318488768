@import '../assets/styles/styles.scss';

// Discover Page

.discover-page{
    .discover{
      section{
        height: auto;
      }
      h2,h6,p{
        // text-transform: capitalize;
      }
    }
  }

// Title Section
.dn-section{
    h2{
      margin-bottom: 25px;
    }
    p{
      @include fluid-typo(18px, 24px);
      @include fluid-line(30px, 36px);
    }
  }
  
  // Text Image Section
  .div-text-image{
    h6{
      margin-bottom: 15px;
    }
    p{
      @include fluid-typo(16px, 18px);
      @include fluid-line(28px, 30px);
    }
    .dti-items{
      .dti-image{
        min-height: 470px;
        background-color: $bills;
      }
    }
  }
  
  // Religion Section
  #religion{
    .dn-container{
      text-align: center;
    }
  }
  
  @media #{$tablet-lg}{
    .div-text-image{
      .dti-items{
        display: flex;
        align-items: center;
        padding-top: 50px;
        &:first-child{
          padding-top: 0;
        }
        &:nth-child(odd){
          .dti-image{
            order: 1;
          }
          .dti-text{
            order: 2;
            padding: 0 7% 0 4%;
          }
        }
        &:nth-child(even){
          .dti-image{
            order: 2;
          }
          .dti-text{
            order: 1;
            padding: 0 4% 0 7%;
          }
        }
      }
    }
  }
  @media #{$desktop}{
    .div-text-image{
      .dti-items{
        padding-top: 80px;
        &:nth-child(odd){
          .dti-text{
            padding: 0 15% 0 5%;
          }
        }
        &:nth-child(even){
          .dti-text{
            padding: 0 5% 0 15%;
          }
        }
      }
    }
  }
  @media #{$desktop-sm}{
    .div-text-image{
      .dti-items{
        padding-top: 110px;
      }
    }
  }
  @media #{$desktop-lg}{
    .div-text-image{
      .dti-items{
        padding-top: 140px;
      }
    }
  }
  @media #{$desktop-xl}{
    .div-text-image{
      .dti-items{
        padding-top: 170px;
        &:nth-child(odd){
          .dti-text{
            padding: 0 20% 0 4%;
          }
        }
        &:nth-child(even){
          .dti-text{
            padding: 0 4% 0 20%;
          }
        }
      }
    }
  }
  @media #{$phablet}{
    .dn-section{
      h2{
        @include font($f: $hkBold);
        margin-bottom: 15px;
      }
    }
    .div-text-image{
      padding: 0 20px;
      h6{
        @include font($f: $hkBold);
        margin-bottom: 5px;
      }
      .dti-items{
        margin-top: 50px;
        &:first-child{
          margin-top: 0;
        }
        .dti-image{
          min-height: 370px;
        }
        .dti-text{
          margin-top: 20px;
        }
      }
    }
  }
  @media #{$mobile} {
    .div-text-image{
      .dti-items{
        .dti-image{
          min-height: 270px;
        }
      }
    }
  }
  