// Columns
.col-one{
  width: 100%;
}
.col-two{
  width: calc(100%/2);
}
.col-three{
  width: calc(100%/3);
}
.col-four{
  width: calc(100%/4);
}

// Has Column Margin
.has-margin{
  .col-two{
    &:first-child{
      /*margin-left: 0;*/
    }
  }
  .col-three{
    &:first-child{
      //margin-left: 0;
    }
  }
}
@media #{$tablet-lg}{
  .has-margin{
    .col-two{
      width: calc(100%/2 - 40px/2);
      margin-left: 40px;
      &:nth-child(odd){
        margin-left: 0;
      }
    }
    .col-three{
      width: calc(100%/3 - 60px/3);
      margin-left: 30px;
      &:nth-child(3n+1){
        margin-left: 0;
      }
      &:nth-child(n+4){
        margin-top: 30px;
      }
    }
  }
}
@media #{$desktop-sm}{
  .has-margin{
    .col-two{
      width: calc(100%/2 - 70px/2);
      margin-left: 70px;
    }
  }
}
@media #{$desktop-xl}{
  .has-margin{
    .col-two{
      width: calc(100%/2 - 100px/2);
      margin-left: 100px;
    }
  }
}
@media #{$phablet}{
  .col-two,.col-three{
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 50px;
    &:first-child{
      margin-top: 0;
    }
  }
  .col-three{
    margin-top: 30px;
  }
}