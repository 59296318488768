.btn-container{
  margin-top: 20px;
}
.btn{
  @include font($f: $hkBold, $s: 16px);
  @include position($p: relative);
  line-height: 24px;
  display: inline-block;
  text-transform: capitalize;
}
.btn-more{
  color: $primary;
  &:after{
    @include position($p: absolute, $l: 0, $b: -5px);
    width: 100%;
    height: 2px;
    display: block;
    content: '';
    background-color: $primary;
  }
}
.btn-give{
  text-transform: uppercase;
}

// Arrow Navigation
.arrow-nav{
  @include position($p: absolute, $r: 50px, $b: 225px);
  @include radius(50%);
  z-index: 9;
  border: 1px solid $bills;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: block;
  background-image: url(../../images/arrow-sm-gray.png);
  a{
    width: 100%;
    height: 100%;
    display: block;
  }
  @media #{$tablet-lg}{
    @include position($r: 30px, $b: 330px);
    width: 86px;
    height: 86px;
    background-size: 40px;
  }
  @media #{$desktop}{
    @include position($b: 200px);
  }
  @media #{$desktop-md}{
    @include position($r: 45px, $b: 220px);
  }
  @media #{$desktop-xl}{
    @include position($r: 60px, $b: 240px);
    width: 96px;
    height: 96px;
    background-size: 45px;
  }
  @media #{$phablet} {
    @include position($r: 20px, $b: 200px);
    width: 65px;
    height: 65px;
    background-size: 25px;
    /*display: none;*/
    // -webkit-transform: rotate(90deg);
    // -moz-transform: rotate(90deg);
    // -ms-transform: rotate(90deg);
    // -o-transform: rotate(90deg);
    // transform: rotate(90deg);
  }
}
