// Body
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font($f: $hkRegular, $s: $font-size-base);
  background-color: $white;
}

// Clearings
.clear {
  clear: both;
  display: block;
}
.clearfix {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
* html,* html {
  .clearfix {
    height: 1%;
  }
}
.clearfix {
  display: block;
}

// Box Sizing
*,*:after,*:before {
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  -webkit-font-smoothing:antialiased;
}