// Global Forms

form{
  width: 100%;
  label{
    @include font($f: $hkMedium, $s: 14px);
    @include position($p: relative);
    display: inline-block;
    line-height: 18px;
    color: $label;
    margin-bottom: 5px;
    span{
      @include position($p: absolute, $t: 3px, $r: -9px);
      @include radius(50%);
      width: 5px;
      height: 5px;
      display: block;
      content: '';
      background-color: $primary;
    }
  }
  input,textarea{
    @include font($f: $hkRegular, $s: 12px);
    width: 100%;
    color: $placeholder;
    padding: 0 15px;
    border: 1px solid $borderLight;
    @include placeholder{
      @include font($f: $hkRegular, $s: 12px);
      color: $placeholder;
    }
  }
  input{
    height: 48px;
    line-height: 48px;
  }
  input[type="submit"]{
    @include font($f: $hkBold, $s: 16px);
    border: 0 none;
    display: inline-block;
    background-color: $primary;
    color: $white;
  }
  textarea{
    min-height: 80px;
    padding: 15px;
    resize: none;
  }
  .form-element-submit{
    label{
      color: transparent;
    }
  }
}