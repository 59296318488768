// Mixins
@mixin transition($range: all, $time: 0.3s, $ease: ease-in-out, $delay: null) {
  transition: $time $range $ease $delay;
}

@mixin delay($time: 1s) {
  transition-delay: $time;
}

@mixin translator($x: 0, $y: 0) {
  transform: translate($x, $y);
}

@mixin font($f: null, $s: null, $st: null, $w: null) {
  font-family: $f;
  font-size: $s;
  font-style: $st;
  font-weight: $w;
}

@mixin position($p: null, $t: null, $r: null, $b: null, $l: null) {
  position: $p;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
}

@mixin radius($radius){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin placeholder {
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin fluid-typo($min-font-size: 12px, $max-font-size: 55px, $lower-range: 480px, $upper-range: 1681px) {
  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}

@mixin fluid-line($min-lh-size: 16px, $max-lh-size: 75px, $lower-range: 480px, $upper-range: 1681px) {
  line-height: calc(#{$min-lh-size} + #{(($max-lh-size / ($max-lh-size * 0 + 1)) - ($min-lh-size / ($min-lh-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    line-height: $min-lh-size;
  }
  @media screen and (min-width: $upper-range){
    line-height: $max-lh-size;
  }
}